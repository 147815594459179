import { Injectable } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

GlobalWorkerOptions.workerSrc = '/assets/pdf.worker.min.mjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadPdfValidatorService {

  constructor() {
  }

  checkPdfPassword(pdfFile: File): Observable<{ isProtected: boolean, pdfFile?: File }> {
    return from(pdfFile.arrayBuffer()).pipe(
      switchMap((pdfData) => {
        return from(pdfjsLib.getDocument({ data: pdfData }).promise).pipe(
          map(() => {
            return { isProtected: false, pdfFile };
          }),
          catchError((error) => {
            if (error.name === 'PasswordException') {
              return of({ isProtected: true });
            } else {
              throw error;
            }
          })
        );
      }),
      catchError((error) => {
        throw error;
      })
    );
  }
}
