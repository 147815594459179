import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { PersonEntityService } from '../../state/entity-services/person-entity.service';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { catchError, map } from 'rxjs/operators';
import { OrganizationService } from '../services/organization.service';
import { OrganizationEntityService } from '../../state/entity-services/organization-entity.service';
import { UrlService } from '../services/url.service';

@Injectable()
export class PatientGuard implements CanActivate {
  constructor(
    private urlService: UrlService,
    private organizationService: OrganizationService,
    private organizationEntityService: OrganizationEntityService,
    private jwtAuthService: JwtAuthService,
    private personEntityService: PersonEntityService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const { person_id, signature, organization: qOrganization } = route.queryParams;
    const user = this.jwtAuthService.getUser();
    const personId = person_id || user?.id;

    if (personId) {
      return this.handlePersonActivation(personId, signature, qOrganization);
    }

    if (qOrganization) {
      return this.handleOrganizationActivation(qOrganization);
    }

    return of(true);
  }

  private handlePersonActivation(personId: number, signature: string, qOrganization: string): Observable<boolean> {
    return this.personEntityService.crmPersons(personId).pipe(
      switchMap(crmPerson => {
        const { patientAppDigitalStatus = 'NO_STATUS', id: crmPersonId } = crmPerson || {};

        switch (patientAppDigitalStatus) {
          case 'FINISHED':
            this.redirectTo(this.urlService.personHome(), {
              personId,
              crmPersonId,
              patientAppDigitalStatus,
            });
            return of(true);

          case 'WAITING_FORM_SUBMISSION':
            if (signature && qOrganization && personId) {
              return of(true);
            }
            return this.generateSelfieLinkAndRedirect(personId);

          case 'WAITING_PREVIOUS_EXAMS':
            this.redirectTo(this.urlService.personHomePreviousExams(), {
              personId,
              crmPersonId,
              patientAppDigitalStatus,
            });
            return of(true);

          case 'WAITING_PERFORMED_EXAM':
            this.redirectTo(this.urlService.personHome(), {
              personId,
              crmPersonId,
              patientAppDigitalStatus,
            });
            return of(true);

          case 'WAITING_PRE_REFERRAL_CARE':
          case 'WAITING_CARE':
          case 'WAITING_EXAM_REQUEST':
          case 'WAITING_REFERRALS':
            this.redirectTo(this.urlService.personWaitingPage(), {
              personId,
              crmPersonId,
              patientAppDigitalStatus,
            });
            return of(true);

          default:
            this.redirectTo(this.urlService.personStatus(), {
              personId,
              crmPersonId,
              patientAppDigitalStatus,
            });
            return of(true);
        }
      })
    );
  }

  private generateSelfieLinkAndRedirect(personId: number): Observable<boolean> {
    return this.personEntityService.generateSelfieLink(personId).pipe(
      map(res => {
        const [baseUrl, queryParams] = res.split('.net/');
        const [organization, , queryParamsString] = queryParams.split('/');
        const signature = queryParamsString.split('signature=')[1];
        this.redirectTo('/paciente/confirmacao/idade', { person_id: personId, signature, organization });
        return true;
      }),
      catchError(() => of(true))
    );
  }

  private handleOrganizationActivation(qOrganization: string): Observable<boolean> {
    return this.organizationEntityService.getWhitelabel(qOrganization).pipe(
      map(organizationResponse => {
        this.organizationService.setOrganization(organizationResponse);
        return true;
      })
    );
  }

  private redirectTo(path: string, queryParams = {}): void {
    this.router.navigate([path], { queryParams });
  }
}
