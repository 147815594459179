import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, } from '@angular/router';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { Observable } from 'rxjs';
import { PersonLegalsEntityService } from '../../state/entity-services/person-legals-entity.service';
import { map } from 'rxjs/operators';

@Injectable()
export class LegalTermsGuard implements CanActivate {
  constructor(
    private personLegalsEntityService: PersonLegalsEntityService,
    private router: Router, private jwtAuth: JwtAuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.jwtAuth.isLoggedIn) {
      const user = this.jwtAuth.getUser();
      return this.personLegalsEntityService.getLegalTermVersion(user.id).pipe(
        map(response => {
          if(response.length > 0){
            this.personLegalsEntityService.lLegalTermVersions = response;
            this.router.navigate(['/paciente/termos'], {
              queryParams: {
                urlRedirect: encodeURIComponent(state.url),
              },
              queryParamsHandling: 'merge'
            });
          }
          return true;
        })
      );
    } else {
      this.router.navigate(['/paciente/login/boas-vindas'], {
        queryParams: {
          return: state.url,
        },
      });
      return false;
    }
  }
}
