import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { PersonEntityService } from '../../state/entity-services/person-entity.service';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { AmplitudeService } from '../services/amplitude.service';
import { CrmPersons } from '../../state/models/crm-persons';

@Injectable()
export class PatientStatusGuard implements CanActivate {
  constructor(
    private amplitudeService: AmplitudeService,
    private jwtAuthService: JwtAuthService,
    private personEntityService: PersonEntityService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const { personId: queryPersonId } = route.queryParams;
    const user = this.jwtAuthService.getUser();
    const personId = queryPersonId || user?.id;

    if (!personId) {
      return of(true);
    }

    if (this.personEntityService.crmPersonsStorage) {
      this.handleStatusCheck(this.personEntityService.crmPersonsStorage, route);
      return true;
    }

    return this.personEntityService.crmPersons(personId).pipe(
      switchMap(crmPerson => {
        this.handleStatusCheck(crmPerson, route);
        return of(true);
      })
    );
  }

  private handleStatusCheck(crmPerson: CrmPersons, route: ActivatedRouteSnapshot): void {
    const user = this.jwtAuthService.getUser();
    const { personId: queryPersonId, patientAppDigitalStatus: statusFromUrl = 'NO_STATUS' } = route.queryParams;

    const { patientAppDigitalStatus = 'NO_STATUS', id: crmPersonId } = crmPerson || {};
    const personId = queryPersonId || user?.id;

    this.amplitudeService.setIdentify();

    if (patientAppDigitalStatus !== statusFromUrl) {
      this.redirectTo('', { patientAppDigitalStatus, personId, crmPersonId });
    }
  }

  private redirectTo(path: string, queryParams: object = {}): void {
    this.router.navigate([path], { queryParams });
  }
}
